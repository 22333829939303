import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;

export const Input = styled.input`
  background: #fff;
  box-shadow: none;
  border-radius: 16px;
  padding: 8px 8px 8px 32px;
  width: 100%;
  border: 0px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;
