import styled from 'styled-components';

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
`;

export const OnNotFound = styled.div`
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
