export const ADD_PRODUCT = 'ADD_PRODUCT'
export type ADD_PRODUCT = typeof ADD_PRODUCT

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export type REMOVE_PRODUCT = typeof REMOVE_PRODUCT

export const CURRENCY_FORMAT = '0.00'
export type CURRENCY_FORMAT = typeof CURRENCY_FORMAT

export const LOAD_MARKET = 'LOAD_MARKET'
export const MARKETS = 'MARKETS'

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'

export const PRODUCERS = 'PRODUCERS'

export const SELECT_MARKET = 'SELECT_MARKET'
